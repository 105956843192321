import { Tooltip } from "antd";
import startCase from "lodash/startCase";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getEditURL } from "../../_helpers/editor";
import { getPublicImageURL } from "../../_helpers/image";
import { CREATOR_BASE_URL } from "../../constants";
import sample_img from "../../media/imgs/website_skelton.jpg";
import ItemOptionMenu from "./ItemOptionMenu";
import PreviewTemplate from "./PreviewTemplate";
import styles from "./styles/CreativeNew.module.css";

const CREATOR_URL = CREATOR_BASE_URL;

export const SingleItem = ({ item, me, isMobile = false, onView = () => { },
    enable_selection = false,
    onSelect = () => { },
    onDeSelect = () => { },
    selected = false
}) => {
    const [open, setOpen] = useState(false)

    return (
        <div className={styles.gridItem} key={item.id}>
            <div className={styles.gridItemImg} style={{
                outline: selected ? "3px dashed royalblue" : null
            }}>
                <img
                    className={styles.giImg}
                    src={
                        item.preview
                            ? getPublicImageURL(item.preview)
                            : sample_img
                    }
                    alt="img"
                />
                <div className={styles.giOverley} onClick={onView}>
                    <Tooltip title="Edit">
                        <button className={styles.giBtn} onClick={(e) => {
                            e.stopPropagation();
                            window.open(getEditURL(item.id), '_blank');
                        }}>
                            <i className="fas fa-pen"></i>
                        </button>
                    </Tooltip>
                    <Tooltip title="View">
                        <button className={styles.giBtn} onClick={(e) => {
                            e.preventDefault();
                            onView();
                        }}>
                            <i className="fas fa-eye"></i>
                        </button>
                    </Tooltip>
                    {enable_selection && <Tooltip title="Select">
                        <i style={{
                            position: "absolute",
                            top: 10,
                            left: 10,
                            backgroundColor: "transparent",
                            color: selected ? "#4169E1" : "white",
                            cursor: "pointer",
                            fontSize: "1.2rem",
                        }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (selected) {
                                    onDeSelect();
                                } else {
                                    onSelect();
                                }
                            }}
                            className="fas fa-square"></i>

                    </Tooltip>}

                    {/* <PreviewTemplate
                            item={item}
                            open={open}
                            setOpen={setOpen}
                            trigger={
                                <Tooltip title="View">
                                    <button className={styles.giBtn}>
                                        <i className="fas fa-eye"></i>
                                    </button>
                                </Tooltip>
                            }
                            is_template={false}
                            slug_field="slug"
                            enable_img_preview={false}
                            me={me}
                            isMobile={isMobile}
                        /> */}

                    <Tooltip title="Edit">
                        <Link to={`/creatives/edit/${item.id}`}>
                            <button className={styles.giBtn}>
                                <i className="fas fa-cog"></i>
                            </button>
                        </Link>
                    </Tooltip>
                </div>

            </div>
            <div className={styles.itemBottomPanel}>
                {!isMobile && <ItemOptionMenu item={item}>
                    <button className={styles.itemBottomBtn}>
                        <i className="fas fa-ellipsis-h"></i>
                    </button>
                </ItemOptionMenu>}
                <div className={styles.itemBottomTextPanel}>
                    <Tooltip title={item.title}>
                        <h2 className={styles.gridItemTitle}>{item.title}</h2>
                    </Tooltip>
                    <p className={styles.gridItemSubtitle}>
                        {startCase(item.type)}
                    </p>
                </div>
            </div>
        </div>
    );
};

