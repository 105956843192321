import { Button } from "antd";
import { useItemsFine } from "../../../_actions/app/items";
import { getImageURL, getPublicImageURL } from "../../../_helpers/image";
import styles from "../styles/BrandStyles.module.css";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import AddBrandOrganization from "./AddBrandOrganization";
import { useCurrentUser } from "../../../actions/auth";
import log from "cslog"
import { useHistory } from "react-router-dom";

export default function BrandOrganization({ prefill = {} }) {
    const { isLoading: loadingMe, isSuccess: successMe, data: dataMe } = useCurrentUser();
    const [show, setShow] = useState(false);
    const { data, isSuccess, isLoading } = useItemsFine(
        "qdb_organization",
        // "filter[owner][_eq]=$CURRENT_USER&fields=*,users.*",
        `fields=*,users.*&filter={"_or":[{"owner":{"_eq":"$CURRENT_USER"}},{"users":{"directus_users_id":{"_eq":"$CURRENT_USER"}}}]}`,
        true
    );
    const [selected, setSelected] = useState(null);
    let history = useHistory();

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!isSuccess) {
        return <p>Something went wrong</p>;
    }

    log.d(data, "Data all orgs");
    return (
        <div className={styles.container}>
            <div className={styles.buttons_wrapper}>
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setSelected(null);
                        setShow(true);
                    }}
                >
                    Add New Organization
                </Button>
                <Button>Join Organization</Button>
            </div>
            <AddBrandOrganization
                show={show}
                setShow={setShow}
                initialValues={selected || {}}
                key={`shivam_${Math.random()}`}
                current_user={dataMe}
            />
            <div className={styles.grid_wrapper}>
                {data.map((item) => {
                    const is_owner = item.owner === dataMe?.id;
                    return (
                        <div
                            className={styles.item_wrapper}
                            key={item.id}
                            onClick={() => {
                                if (is_owner) {
                                    history.push(`/settings/brand/${item.id}/edit`);
                                    // setSelected(item);
                                    // setShow(true);
                                }
                            }}
                            style={{
                                cursor: is_owner ? "pointer" : "default",
                                backgroundColor: is_owner ? "white" : "#f3f3f3"
                            }}
                        >
                            <img
                                className={styles.img_organization}
                                // src={getImageURL(item.logo)}
                                src={getPublicImageURL(item.logo)}
                                alt={item.name}
                            />
                            <p className={styles.name}>{item.name}</p>
                            <span className={styles.badge} style={{
                                backgroundColor: is_owner ? "#7fd17f" : "#7fb3d1"
                            }}>
                                {is_owner ? "Owner" : "Member"}
                            </span>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
