import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import { Button, Input } from "antd";
import { useDebounce } from "../utils/input";

function CreationsPicker() {
    const [open, setOpen] = useState(false);
    const [search_term, setSearchTerm] = useState("");
    const search_query = useDebounce(search_term, 1000);


    return (
        <>
            <Button type="primary" onClick={() => setOpen(true)}>
                Add Creations
            </Button>
            <Modal
                title="Title"
                open={open}
            >
                <Input
                    value={search_term}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {search_term}
                {search_query}
            </Modal>
        </>
    )
}

export default CreationsPicker