import React, { useState } from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { useEventCalender } from "../actions/events";
import log from "cslog";
import styles from "./styles/ContentCalender.module.css";
import { PreviewTemplateList } from "../qmade/creator/PreviewTemplateList";
import { getImageFileURL, getImageURL, getPublicImageURL } from "../_helpers/image";
import { Button } from "antd";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

function ContentCalender({ me = {} }) {
    const { isSuccess, isLoading, data } = useEventCalender(true);
    const [open, setOpen] = useState(false)
    const [selected_card, setSelectedCard] = useState(null);

    const current_month = new Date().getMonth();
    const current_day_of_month = new Date().getDate();
    const months_seq = [
        ...MONTHS.slice(current_month, MONTHS.length),
        ...MONTHS.slice(0, current_month),
        MONTHS[current_month]
    ]


    // log.d(data, "Event calender data");
    // log.d(open, "Now Open is")
    log.d(months_seq, "Months Seq")
    log.d(current_month, "Current Month")
    log.d(current_day_of_month, "Current Day")

    return (
        <QurealSection header={<SectionHeader
            heading="Content Calender"

        >
            <Button type="primary" >Add Event</Button>
        </SectionHeader>}>
            {/* <PreviewTemplateList
                    tags={["gandhi"]}
                    enable={true}
                    open={true}
                    setOpen={setOpen}
            /> */}
            <div className={styles.wrapper}>
                {isSuccess &&
                    months_seq.map((month, index) => (
                        // <MonthSection month={month} data={data[index]}
                        <MonthSection
                            month={month}
                            start_day={index === 0 ? current_day_of_month : false}
                            end_day={index === 12 ? current_day_of_month : false}
                            data={data[
                                current_month + index > 11 ? current_month + index - 12 : current_month + index
                            ]}
                            key={index}
                            onCardClick={(item) => {
                                log.d(item, "Item clicked card")
                                setOpen(true)
                                setSelectedCard(item)
                            }}
                        />
                    ))}
            </div>
            {selected_card && <PreviewTemplateList
                // enable={open && selected_card?.filter !== null}
                enable={open}
                // filter={selected_card?.filter ? encodeURIComponent(JSON.stringify(selected_card.filter)) : null}
                filter={selected_card?.filter ? JSON.stringify(selected_card.filter) : null}
                open={open}
                setOpen={setOpen}
                me={me}
            />}
        </QurealSection>
    );
}

function MonthSection({ month, data = [], onCardClick, start_day, end_day }) {

    if (data.length === 0) {
        return null;
    }
    return (
        <div className={styles.month_wrapper}>
            <h5 className={styles.month_name}>{month}</h5>
            {data.length > 0 && (
                <div className={styles.items_wrapper}>
                    {data
                        .sort((a, b) => a.dt - b.dt)
                        .filter(item => (start_day ? item.dt.getDate() >= start_day : true) && (end_day ? item.dt.getDate() <= end_day : true))
                        .map((item) => (
                            <OneCard item={item} month={month} onCardClick={onCardClick} />
                        ))}
                </div>
            )}
        </div>
    );
}

function OneCard({ item, month, onCardClick }) {
    return (
        <div className={styles.item_wrapper} onClick={() => onCardClick(item)}
            style={item.feature_image ? {
                backgroundImage: `url(${getPublicImageURL(item.feature_image)})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
            } : {}}
        >
            <p className={styles.item_title}>{item.name}</p>
            {/* <p>{item.date}</p> */}
            <div className={styles.item_dt_wrapper}>
                <p className={styles.item_dt}>{item.dt?.getDate()}</p>
                <p className={styles.item_month_name}>{month}</p>
            </div>
        </div>
    );
}

export default ContentCalender;
