
import { useParams } from "react-router-dom";
import QurealSection from "../../../common/QurealSection";
import SectionHeader from "../../../components/commons/SectionHeader";
import { useItemsFine, useSingleItem } from "../../../_actions/app/items";
import { getImageURL, getPublicImageURL } from "../../../_helpers/image";
import styles from "../styles/BrandStyles.module.css";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import AddBrandOrganization from "./AddBrandOrganization";
import { useCurrentUser } from "../../../actions/auth";
import log from "cslog";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import PhotoButton from "../../../qforms/standard_elements/PhotoButton";
import { useCreateItem, useUpdateItem } from "../../../_actions/mutations/items";
import { useHistory } from "react-router-dom"
import { nanoid } from "nanoid"
import snakeCase from "lodash/snakeCase";



function BrandEditPage({ params }) {
    const { id } = useParams();
    const history = useHistory();
    const { isLoading: loadingMe, isSuccess: successMe, data: dataMe } = useCurrentUser();
    const [show, setShow] = useState(false);
    const { data, isSuccess, isLoading } = useSingleItem(
        "qdb_organization",
        // "filter[owner][_eq]=$CURRENT_USER&fields=*,users.*",
        `/${id}?fields=*,users.*&filter={"_or":[{"owner":{"_eq":"$CURRENT_USER"}},{"users":{"directus_users_id":{"_eq":"$CURRENT_USER"}}}]}`,
        true
    );
    const [form] = Form.useForm();
    const mutationCreate = useCreateItem("qdb_organization", () => {
        history.push("/settings/brands")
    }
    );
    const mutationUpdate = useUpdateItem(
        "qdb_organization",
        id,
        () => { history.push("/settings/brands") }
    );

    const is_update = true;

    log.d(data, "Brand data")

    const addOrganization = (values) => {
        log.d(values, "Person values");
        const new_data = {
            ...values,
            id: `${snakeCase(values.name)}_${nanoid(5)}`,
            owner: dataMe?.id,
            users: [{ directus_users_id: dataMe?.id }],
            is_personal: false,
            logo_square: values.logo_square || values.logo,
            logo_dark: values.logo_dark || values.logo
        }
        log.d(new_data, "New data");
        // return;
        if (!!id) {
            log.p("Updating organization");
            mutationUpdate.mutate(new_data);
        } else {
            log.p("Creating organization");
            mutationCreate.mutate(new_data);
        }
    };

    return <QurealSection header={<SectionHeader heading="Edit Brand" >
        <Button key="cancel" onClick={() => {
            // history.goBack();
            history.push("/settings/brands");
        }}>
            Cancel
        </Button>
        <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={
                mutationCreate.isLoading || mutationUpdate.isLoading
            }
        >
            {is_update ? "Update" : "Create"}
        </Button>
    </SectionHeader>} >
        <div style={{ padding: "15px", maxWidth: "700px" }}>
            {/* <p>params: {id}</p> */}
            {/* {isSuccess && <p>{JSON.stringify(data)}</p>} */}
            {isLoading && <p>Loading....</p>}
            {(isSuccess && data && data.owner !== dataMe?.id) && <p>You are not authorized to edit this brand</p>}
            {(isSuccess && data && data.owner === dataMe?.id) && <Form
                layout="vertical"
                form={form}
                onFinish={addOrganization}
                initialValues={{
                    // ...initialValues,
                    ...data,
                    // ...(logo_hori && { logo_horizontal: logo_hori }),
                }}
            >
                <Form.Item label="Organization Name" name="name">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Organization Description"
                    name="description"
                >
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item label="Tagline" name="tagline">
                    <Input />
                </Form.Item>
                <Row gutter={[10, 10]}>
                    <Col span={12}>
                        <Form.Item label="Default Logo" name="logo">
                            <PhotoButton
                                field={{
                                    collection: "qqq",
                                    field: "logo",
                                }}
                                width="220px"
                                height="100px"
                                objectFit="contain"
                                show_placeholder={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12} >
                        <Form.Item label="Square Logo" name="logo_square">
                            <PhotoButton
                                field={{
                                    collection: "qqq",
                                    field: "logo_square",
                                }}
                                height="100px"
                                width="100px"
                                objectFit="contain"
                                show_placeholder={true}
                            />
                        </Form.Item>
                        <Checkbox onChange={(e) => {
                            if (e.target.checked) {
                                log.d(form.getFieldValue("logo"), "settintg logo_square to")
                                form.setFieldsValue({
                                    logo_square: form.getFieldValue("logo"),
                                    logo_square_old: form.getFieldValue("logo_square"),
                                })
                            } else {
                                if (form.getFieldValue("logo_square") === form.getFieldValue("logo")) {
                                    form.setFieldsValue({
                                        logo_square: form.getFieldValue("logo_square_old") || null
                                    })
                                }
                            }
                            log.d(form.getFieldsValue(), "form values now")
                        }}>Use same as default logo</Checkbox>
                    </Col>
                    <Col span={12} >
                        <Form.Item label="Default Logo (for dark background)" name="logo_dark">
                            <PhotoButton
                                field={{
                                    collection: "qqq",
                                    field: "logo_dark",
                                }}
                                width="220px"
                                height="100px"
                                objectFit="contain"
                                show_placeholder={true}
                                background="black"
                            />
                        </Form.Item>
                        <Checkbox style={{ paddingBottom: "10px" }} onChange={(e) => {
                            if (e.target.checked) {
                                log.d(form.getFieldValue("logo"), "settintg logo_dark to")
                                form.setFieldsValue({
                                    logo_dark: form.getFieldValue("logo"),
                                    logo_dark_old: form.getFieldValue("logo_dark"),
                                })
                            } else {
                                if (form.getFieldValue("logo_dark") === form.getFieldValue("logo")) {
                                    form.setFieldsValue({
                                        logo_dark: form.getFieldValue("logo_dark_old") || null
                                    })
                                }
                            }
                            log.d(form.getFieldsValue(), "form values now")
                        }}>Use same as default logo</Checkbox>
                    </Col>
                </Row>

                <Row gutter={[10, 10]}>
                    {/* <Col span={8}> */}
                    <Col xs={24} sm={12}>
                        <Form.Item label="Primary Phone" name="primary_phone">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Primary Email" name="primary_email">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Address" name="address">
                    <TextArea rows={3} />
                </Form.Item>
                <Form.Item label="Website" name="website">
                    <Input />
                </Form.Item>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                }}>
                    <Button key="cancel" onClick={() => {
                        history.push("/settings/brands");
                    }}>
                        Cancel
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => form.submit()}
                        loading={
                            mutationCreate.isLoading || mutationUpdate.isLoading
                        }
                    >
                        {is_update ? "Update" : "Create"}
                    </Button>
                </div>
            </Form>}
        </div>
    </QurealSection>
}

export default BrandEditPage;