import { Button, Checkbox, Drawer, Modal, Select } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { getTime } from "../../_helpers/app/dt";
import { getImageFileURL, getPublicImageURL } from "../../_helpers/image";
import { PREVIEW_CREATION_URL, PREVIEW_TEMPLATE_URL } from "../../constants";
import RelationFieldStanard from "../../qforms/standard_elements/RelationFieldStandard";
import styles from "./styles/Preview.module.css";
import log from "cslog"
import { QurealViewer, useQurealActions } from "qureal-editor"

import { useHistory } from 'react-router-dom'



function PreviewTemplate({ item, trigger, slug_field = "id", is_template = false,
    enable_nav = false,
    enable_prev = false,
    enable_next = false,
    onPrevClick = () => { },
    onNextClick = () => { },
    open,
    setOpen,
    enable_img_preview = true,
    me = {},
    isMobile = false
}) {
    const [iopen, setIOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory()

    const [org, setOrg] = useState(me?.selected_org?.id);
    const [person, setPerson] = useState(null);
    const [preview, setPreview] = useState(true);

    const { actionRef, download, share } = useQurealActions();

    useEffect(() => {
        if (iopen) {
            setLoading(true);
        } else {
            setLoading(false)
        }
    }, [iopen]);

    // useEffect(() => {
    //     window.onpopstate = (e) => {
    //         alert("back pressed")
    //         e.stopPropagation();
    //         if (open) {
    //             setOpen(false)
    //         }
    //     }
    // }, [])

    const url = `${is_template ? PREVIEW_TEMPLATE_URL : PREVIEW_CREATION_URL}${item[slug_field]}`
    const editor_url = `https://editor.qureal.com/${item?.id}/edit`
    let customized = false

    let iframe_url = `${url}?`;
    if (org) {
        iframe_url += `qi_organization=${org}`
        customized = true;
    }
    if (person) {
        iframe_url += `&qi_person=${person}`
        customized = true;
    }

    log.d(me, "Me in PreviewTemplate")

    return (
        <Fragment>
            {trigger && <span onClick={(e) => {
                e.preventDefault();
                if (item?.type === "singlepage") {
                    setIOpen(true)
                } else {
                    window.open(url, '_blank');
                }
            }}>{trigger}</span>}

            <PreviewWrapper
                trigger={trigger}
                open={open}
                setOpen={setOpen}
                iopen={iopen}
                setIOpen={setIOpen}
                isMobile={isMobile}
                title={item.title}
            >
                <div className={styles.wrapper}>
                    <div className={styles.left_box}>
                        <QurealViewer
                            // slug={item.slug}
                            slug={item[slug_field]}
                            preview={preview}
                            style={{
                                width: isMobile ? "93vw" : "85vh",
                                height: isMobile ? "93vw" : "85vh",
                                outline: "none",
                                border: "1px solid #777"
                            }}
                            organization={org}
                            person={person}
                            actionRef={actionRef}
                            type={is_template ? "template" : "creation"}
                            fit={true}
                            background="white"
                        />
                    </div>
                    {/* <div className={styles.left_box}>
                        {(customized || !enable_img_preview) ?
                            <iframe
                                title={item.title}
                                src={`${iframe_url}&preview&qi_fit`}
                                width={500}
                                className={styles.web_box}
                                onLoad={() => setLoading(false)}
                            // loading="lazy"
                            /> : <img
                                className={styles.web_box}
                                src={getImageFileURL(item.preview?.filename_disk)}
                                alt="preview"
                            />}
                    </div> */}
                    <div className={styles.right_box}>
                        {/* <p>{getImageFileURL(item?.preview?.filename_disk)}</p> */}
                        {/* <p>iFrame URL: {iframe_url}</p> */}
                        {/* {loading && <p>Loading...</p>} */}
                        <p>Org: {org}</p>
                        {!isMobile && <h3 className={styles.title}>{item.title}</h3>}
                        <p className={styles.creation_type}>{item.creation_type?.name}</p>
                        {!is_template && <p className={styles.last_updated}>Last updated {getTime(item.date_updated, "rt")} ago</p>}
                        <div className={styles.action_wrapper}>
                            {!is_template && <a href={editor_url} target="_blank" rel="noreferrer">
                                <Button>Edit</Button>
                            </a>}
                            <a href={url} target="_blank" rel="noreferrer">
                                <Button>Full page view</Button>
                            </a>
                            <Button onClick={share}>Share</Button>
                            <Button onClick={download}>Download</Button>
                        </div>

                        <div className={styles.select_wrapper}>
                            <Select
                                allowClear
                                onChange={(val) => setOrg(val)}
                                defaultValue={org}
                                showSearch={!isMobile}
                                // filterOption={filterOption}
                                options={me?.organizations?.filter(item => item.qdb_organization_id !== null).map(
                                    (item) => ({
                                        label: item.qdb_organization_id?.is_personal ? "Personal" : item.qdb_organization_id?.name,
                                        value: item.qdb_organization_id?.id
                                    })
                                )}
                                style={{
                                    width: 200,
                                    marginTop: "15px"
                                }}
                            />

                            <RelationFieldStanard
                                field={{
                                    foreign_key_table: "qdb_person",
                                    template: "{{full_name}}",
                                    foreign_key_column: "id"
                                }}
                                value={person}
                                onChange={new_person => setPerson(new_person)}
                                style={{
                                    width: 200,
                                    marginTop: "15px"
                                }}
                                showSearch={!isMobile}
                            />
                        </div>
                        <Checkbox
                            checked={preview}
                            onChange={(e) => setPreview(e.target.checked)}
                        >Enable preview mode</Checkbox>

                        {enable_nav && <div className={styles.page_control_wrapper}>
                            <Button disabled={!enable_prev} onClick={onPrevClick}>Previous</Button>
                            <Button disabled={!enable_next} onClick={onNextClick}>Next</Button>
                        </div>}

                    </div>
                </div>
            </PreviewWrapper>
        </Fragment>
    );
}

export default PreviewTemplate;


function PreviewWrapper({ children, trigger, open, setOpen, iopen, setIOpen, isMobile, title }) {
    if (isMobile) {
        return <Drawer
            // title="Basic Drawer"
            // title={null}
            title={<div style={{
                margin: "-6px -12px -12px -12px"
            }}>
                {title}
            </div>}
            placement="bottom"
            closable={false}
            onClose={() => setOpen(false)}
            open={open}
            key="qureal_preview_drawer"
            // size="large"
            height="80vh"
            closeIcon={true}
        // getContainer={document.getElementById("preview_drawper_base")}
        // title={<div style={{
        //     width: "100%",
        //     height: "0px",
        //     margin: "-10px"
        // }} />}
        >
            {children}
        </ Drawer>
    }
    return <Modal
        // title="Template Preview"
        open={trigger ? iopen : open}
        centered
        onOk={() => {
            setIOpen(false);
            if (!trigger) {
                setOpen(false);
            }
        }}
        onCancel={() => {
            setIOpen(false)
            if (!trigger) {
                setOpen(false)
            }
        }}
        width={1000}
        footer={null}
        style={{
            padding: "0px",
            // border: "2px dotted red"
        }}
    >
        {children}
    </Modal>
}